import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';

import _ from 'lodash';
import { ReportHeader } from './common';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);
const genBody = (Products) => {
  const productList = _.map(Products, (product, index) => [
    { text: index + 1, alignment: 'center' },
    {
      text: [
        { text: `${product?.name}\n` },
        { text: `${product?.about || ''}`, fontSize: 8 },
      ],
      alignment: 'left',
    },
    { text: `${product?.quantity} ${product?.unit}`, alignment: 'center' },
    {
      text: `${product?.price
        ?.toFixed(2)
        ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
      alignment: 'right',
    },

    {
      text: `${product?.discount
        ?.toFixed(2)
        ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
      alignment: 'right',
    },
    {
      text: `${(product?.price * product?.quantity - product?.discount)
        ?.toFixed(2)
        ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
      alignment: 'right',
    },
  ]);

  return productList;
};

const inReport = async (me1, data, information, checked, me) => {
  console.log('staTUS 3', me.status, 'status7', data.statusTax);
  const THBText = require('thai-baht-text');
  const sumPrice = _.sumBy(
    data?.product,
    (each) => each?.price * each?.quantity
  );
  const sumDiscount = _.sumBy(data?.product, (each) =>
    parseInt(each?.discount)
  );
  const Price = () => {
    return sumPrice - sumDiscount;
  };

  const PriceString = (sumPrice - sumDiscount)?.toFixed(2);

  //console.log('Price', Price);
  const taxPrice = () => {
    return Price() * 0.07;
  };
  console.log('typetax', typeof taxPrice(data?.statusTax));

  // const taxThree = () => {
  //   return cal?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  // };

  // const calculatedTax = (data) => {
  //   return taxPrice(data.statusTax);
  // };
  // console.log('Typecal .', typeof calculatedTax(data));

  console.log('taxPrice', taxPrice(data.statusTax));

  console.log('Price', Price());
  const sumAllPrice = () => {
    return ((Price() || 0) + (taxPrice(data?.statusTax) || 0)).toFixed(2);
  };

  const taxAllUni = (statusTaxUni) => {
    return (sumAllPrice - taxPrice(me.status))?.toFixed(2);
  };

  const genItemBody = genBody(data?.product);
  console.log('data', data);
  console.log('sumAllPrice', sumAllPrice());

  console.log('pdf', sumPrice);

  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'portait',
    pageMargins: [20, 270, 20, 270],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    info: {
      title: `${data?.prefix}${data?.running_number}`,
      subject: `${data?.prefix}${data?.running_number}`,
    },
    header: [
      ReportHeader(information),

      {
        alignment: 'center',
        margin: [20, -2, 20, 4],
        table: {
          alignment: 'center',

          widths: ['49.9%', '12.8%', '13%', '*'],
          body: [
            [
              {
                fillColor: '#e87800',
                rowSpan: 2,
                text: 'ใบแจ้งหนี้/ใบส่งสินค้า',
                margin: 10,
                style: 'header1',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              { text: 'เลขที่' },
              {
                text: `${data?.prefix}${data?.running_number}`,
                alignment: 'left',
              },
            ],
            [
              '',
              {
                text: '',
                border: [false, false, false, false],
              },
              { text: 'วันที่', margin: [0, 4, 0, 0] },
              {
                text: `${dayjs(data?.date).format('DD MMMM BBBB')}`,
                alignment: 'left',
                margin: [0, 4, 0, 0],
              },
            ],
          ],
        },
      },
      {
        margin: [20, -2, 20, 0],
        table: {
          alignment: '',

          widths: ['15%', '34%', '13%', '13%', '*'],
          body: [
            [
              {
                text: 'ชื่อลูกค้า',
                style: 'body',
                border: [true, true, false, false],
              },
              {
                text: `${data?.customer?.businessname || '-'}`,
                border: [false, true, true, false],
                style: 'body',
              },
              { text: '', border: [false, false, false, false] },
              {
                text: 'วิธีการชำระเงิน',
                style: 'body',
                border: [true, true, false, false],
              },
              {
                text: `${data?.payment || '-'}`,
                style: 'body',
                border: [false, true, true, false],
              },
            ],

            [
              {
                text: 'เลขที่ผู้เสียภาษี ',
                style: 'body',
                border: [true, false, false, false],
              },
              {
                text: `${data?.customer?.TaxId || '-'}`,
                style: 'body',
                border: [false, false, true, false],
              }, // texid
              { text: '', border: [false, false, false, false] },
              { text: '', border: [true, false, false, false] },
              { text: '', border: [false, false, true, false] },
            ],
            [
              {
                text: 'ชื่อผู้ติดต่อ',
                style: 'body',
                border: [true, false, false, false],
              },
              {
                text: `${data?.customer?.name || '-'}`,
                style: 'body',
                border: [false, false, true, false],
              },
              { text: '', border: [false, false, false, false] },
              {
                text: 'จำนวนวันเครดิต',
                style: 'body',
                border: [true, false, false, false],
              },
              {
                text: `${data?.credit || '-'}`,
                style: 'body',
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: 'ที่อยู่',
                style: 'body',
                border: [true, false, false, false],
              },
              {
                text: `${data?.customer?.address || '-'}`,
                style: 'body',
                border: [false, false, true, false],
              },
              { text: '', border: [false, false, false, false] },
              {
                text: 'กำหนดครบชำระ',
                style: 'body',
                border: [true, false, false, false],
              },
              {
                text: `${dayjs(data?.dueDate).format('DD MMMM BBBB')}`,
                style: 'body',
                border: [false, false, true, false],
              },
            ],
            [
              {
                text: 'เบอร์โทร',
                style: 'body',
                border: [true, false, false, true],
              },
              {
                text: `${data?.customer?.tel || '-'}`,
                style: 'body',
                border: [false, false, true, true],
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [true, false, false, true] },
              { text: '', border: [false, false, true, true] },
            ],

            // ...order
          ],
        },
      },
    ],
    content: [
      {
        margin: [0, 12],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['5%', '45.01%', '13%', '13.33%', '10.33%', '13.33%'],
          body: [
            [
              {
                text: 'ลำดับ',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'รายการสินค้า',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'จำนวน',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'ราคาต่อหน่วย',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'ส่วนลด',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'จำนวนเงิน',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            ...genItemBody,
          ],
        },
      },

      {},
      {
        // margin: [0, 12],
      },
    ],
    pageBreakBefore(
      currentNode,
      followingNodesOnPage,
      nodesOnNextPage,
      previousNodesOnPage
    ) {
      return (
        currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0
      );
    },

    footer: [
      {
        margin: [20, 0, 20, 0],
        table: {
          alignment: '',
          widths: ['72%', '*', '12%'],
          body: [
            [
              {
                text: [
                  { text: 'หมายเหตุ\n', bold: true },

                  `${data?.note || '-'}`,
                ],
                bold: true,
                rowSpan: 4,
                border: [false, false, false, false],
              },
              {
                text: 'รวมเงิน',
                border: [false, false, false, false],
                bold: true,
              },
              {
                text: `${sumPrice
                  ?.toFixed(2)
                  ?.replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                border: [false, false, false, false],
                style: 'number',
              },
            ],
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: 'ส่วนลดการค้า',
                border: [false, false, false, false],
                bold: true,
              },
              {
                text: `${
                  sumDiscount
                    ?.toFixed(2)
                    ?.replace(/\d(?=(\d{3})+\.)/g, '$&,') || '-'
                }`,
                style: 'number',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: 'เงินหลังหักส่วนลด',
                border: [false, false, false, false],
                bold: true,
              },
              {
                text: `${
                  PriceString?.replace(/\d(?=(\d{3})+\.)/g, '$&,') || '-'
                }`,
                border: [false, false, false, false],
                style: 'number',
              },
            ],
            [
              {
                text: '',
                border: [false, false, false, false],
                alignment: 'center',
                bold: true,
              },
              {
                text: 'ภาษีมูลค่าเพิ่ม 7%',
                border: [false, false, false, false],
                bold: true,
              },
              {
                text: taxPrice(data?.customer?.status)
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                border: [false, false, false, false],
                bold: true,
                style: 'number',
              },
            ],
            [
              {
                fillColor: '#e87800',
                text: `${THBText(sumAllPrice(data?.customer?.status))}`,
                border: [false, false, false, false],
                alignment: 'center',
                style: 'number',
                bold: true,
              },
              {
                text: 'จำนวนเงินทั้งสิ้น',
                border: [false, false, false, false],
                bold: true,
              },
              {
                fillColor: '#e87800',
                text: sumAllPrice(data?.customer?.status)?.replace(
                  /\d(?=(\d{3})+\.)/g,
                  '$&,'
                ),
                border: [false, false, false, false],
                style: 'number',
                bold: true,
              },
            ],
          ],
        },
      },
      {
        margin: [20, 0, 20, 0],
        alignment: 'center',
        table: {
          alignment: '',
          heights: 40,
          widths: ['33.33%', '33.33%', '33.33%'],
          body: [
            [
              checked
                ? {
                    image: 'sign1',
                    fit: [80, 50],
                    margin: [0, 20, 0, 0],
                    alignment: 'center',
                    border: [true, true, true, false],
                  }
                : {
                    text: [{ text: '\n' }],
                    alignment: 'center',
                    border: [true, true, true, false],
                  },

              {
                text: [{ text: '' }],
                margin: [0, 0, 0, 0],
                alignment: 'center',
                border: [true, true, true, false],
              },
              {
                text: [{ text: '' }],

                alignment: 'center',
                border: [true, true, true, false],
              },
            ],
            [
              {
                text: [
                  { text: '.....................................\n' },
                  { text: 'ผู้แจ้งหนี้/ผู้ส่งสินค้า\n' },
                  {
                    text: ` วันที่ ${dayjs(data.date).format(
                      'DD MMMM BBBB'
                    )} \n `,
                  },
                ],

                alignment: 'center',

                border: [true, false, true, true],
              },
              {
                text: 'ตราบริษัท',
                alignment: 'center',
                margin: [0, 20, 0, 0],
                border: [true, false, true, true],
              },
              {
                text: [
                  { text: '....................................\n' },
                  { text: 'ผู้รับสินค้า\n' },
                  { text: 'วันที่/date............................\n' },
                ],
                alignment: 'center',
                border: [true, false, true, true],
              },
            ],
          ],
        },
      },
    ],

    styles: {
      header: {
        fontSize: 11,
        bold: true,
        alignment: 'right',
      },
      header1: {
        fontSize: 14,
        bold: true,
      },
      body: {
        fontSize: 9,
        alignment: 'left',
        bold: true,
      },
      body1: {
        alignment: 'right',
        fontSize: 10,
        bold: true,
      },
      body2: {
        alignment: 'right',
        fontSize: 8,
      },
      number: {
        alignment: 'right',
        fontSize: 10,
        bold: true,
      },
    },

    images: {
      logo: information?.image?.url,
      sign1: me1?.url,
    },
  };

  pdfMake.createPdf(documentRef).open();
};
export default inReport;
