import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import _, { head } from 'lodash';
// import { table } from 'console';
import { ReportHeader } from './common';
import { title } from 'process';
import salary from 'views/finance/salary';
import { log } from 'async';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const SalaryReport = async (data, information, note, date) => {
  /// calculate Salary By User
  const countTaxSSO = (data) => {
    const sso = data?.salary * (data?.taxSSO / 100);
    if (data?.salary >= 15000) {
      const eSso = 15000 * (data?.taxSSO / 100);
      return eSso;
    }
    return sso;
  };
  console.log('Note in PDF', note);

  const revenue = (data) =>
    data?.salary + data?.ot + data?.extra + data?.allowance;
  const expenses = (data) => {
    const sso = countTaxSSO(data);
    return sso + data?.tax + data?.etc;
  };
  const sumSalary = (data) => revenue(data) - expenses(data);
  console.log('Sumsalary', sumSalary(data));
  /// calculate SumSalary All
  const sumAllSalary = _.sumBy(data?.arr, (each) => each?.salary);
  const sumAllOt = _.sumBy(data?.arr, (each) => each?.ot);
  const sumAllExtra = _.sumBy(data?.arr, (each) => each?.extra);
  const sumAllowance = _.sumBy(data?.arr, (each) => each?.allowance);
  const sumTotal = sumAllSalary + sumAllowance + sumAllExtra;
  console.log('sumTotal', sumTotal);
  const sumAllRevenue = sumAllSalary + sumAllOt + sumAllExtra + sumAllowance;
  /// Calculate Sumrevenue
  const sumAllSSO = _.sumBy(data?.arr, (each) => each?.sumSSO);
  const sumEtc = _.sumBy(data?.arr, (each) => each?.etc);
  const sumTax = _.sumBy(data?.arr, (each) => each?.tax);
  const sumAllexpenses = sumAllSSO + sumEtc + sumTax;
  /// Calculate emExtra
  const sumAllEmExtra1 = _.sumBy(data?.arr, (each) => each?.emExtra);
  const sumAllEmExtra2 = _.sumBy(data?.arr, (each) => each?.emExtra2);
  const genBody = (data) => {
    console.log('data', data);

    const dataList = _.map(data, (eachData, index) => [
      { text: 'PSUIE', fillColor: '#82b1ff', alignment: 'center' },
      { text: index + 1, alignment: 'center' },
      {
        text: `${eachData?.user?.personalID} `,
        style: 'table2',
      },
      {
        text: `${eachData?.user?.name}  ${eachData?.user?.surname}`,
        style: 'table2',
      },
      {
        text: `${
          eachData?.salary?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
          '0.00'
        }`,
        style: 'table1',
      },
      {
        text: `${
          eachData?.ot ||
          eachData?.allowance?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
          '0'
        }`,
        style: 'table1',
      },

      {
        text: `${
          eachData?.extra?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') || '0'
        }`,
        style: 'table1',
        border: [true, true, true, true],
      },
      {
        text: `${
          (
            (eachData?.salary || 0) +
            (eachData?.allowance || 0) +
            (eachData?.extra || 0)
          )

            ?.toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,') || '0'
        }`,
        style: 'table1',
      },
      {
        text: countTaxSSO(eachData)
          ?.toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
        style: 'table1',
        border: [true, true, true, true],
      },

      {
        text: '-',
        style: 'table1',
      },
      {
        text: `${
          sumSalary(eachData)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,') || '0.00'
        }`,

        style: 'table1',
      },
    ]);

    return dataList;
  };
  const genHead = (data) => {
    const dataHead = _.map(data, (eachData, index) => [
      { text: '', border: [false, false, false, false] },
      { text: 'PSUIE', style: 'header1' },
      {
        text: ` ${sumAllSalary
          ?.toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
        style: 'table1',
      },
      {
        text: `${sumAllOt?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
        style: 'table1',
      },
      {
        text: `${sumAllSSO?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
        style: 'table1',
      },
      {
        text: `${sumTax?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
        style: 'table1',
      },
      {
        text: `${(sumAllRevenue - sumAllexpenses)
          ?.toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
        style: 'table1',
      },
      {
        text: '',
        border: [false, false, false, false],
      },
    ]);

    return dataHead;
  };

  const genItemBody = genBody(data.arr);
  const genItemHead = genHead(data.arr);

  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [20, 20, 0, 110],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    info: {
      title: `ใบสรุปเงินเดือน-ประจำเดือน-${dayjs(date).format('MMMM-BBBB')}`,
    },

    content: [
      // {
      //   text: ` ใบสรุปจ่ายเงินเดือน\nประจำเดือน ${dayjs(
      //     data?.arr?.[0]?.date
      //   ).format('MMMM BBBB')} \n ${information?.name}`,
      //   style: 'headerPage',
      //   margin: [0, 0, 0, 20],
      // },
      {
        text: 'บริษัท พีเอสยู อินโนเวทีฟ เอ็นจิเนียริง จำกัด',
        margin: [0, 0, 0, 2],
        style: 'header',
        border: [false, false, false, false],
      },
      {
        text: `รายละเอียดการจ่ายเงินเดือนพนักงาน ประจำเดือน ${dayjs(
          date
        ).format('MMMM BBBB')}`,
        margin: [0, 0, 0, 2],
        style: 'header',
        border: [false, false, false, false],
      },
      {
        text: `กำหนดจ่ายเงิน\t 28 ${dayjs(date).format('MMMM-BBBB')}`,
        margin: [0, 0, 0, 20],
        style: 'header',
        border: [false, false, false, false],
      },
      {
        margin: [0, 0, 0, 20],
        table: {
          widths: [
            '23%', //1
            '15%', //2
            '8%', //3
            '10%', //4
            '10%', //5
            '8%', //6
            '9%', //7
            '*', //8
          ],
          body: [
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: 'โครงการ',
                style: 'header1',
              },
              {
                text: 'เงินเดือนที่จ่าย',
                style: 'header1',
              },
              {
                text: 'เบี้ยเลี้ยง/ค่าทำงานวันหยุด',
                style: 'header1',
              },
              {
                text: 'หัก [ประกันสังคม (5%)]',
                style: 'header1',
              },
              {
                text: 'หัก [ภาษี ณ ที่จ่าย]',
                style: 'header1',
              },
              {
                text: 'คงเหลือจ่ายจริง',
                style: 'header1',
              },
              {
                text: '',
                border: [false, false, false, false],
              },
            ],
            // ...genItemHead,
            [
              { text: '', border: [false, false, false, false] },
              { text: 'PSUIE', style: 'header1' },
              {
                text: ` ${sumAllSalary
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'table1',
              },
              {
                text: `${
                  sumAllOt ||
                  sumAllowance?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                }`,
                style: 'table1',
              },
              {
                text: `${sumAllSSO
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'table1',
              },
              {
                text: `${sumTax
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'table1',
              },
              {
                text: `${(sumAllRevenue - sumAllexpenses)
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'table1',
              },
              {
                text: '',
                border: [false, false, false, false],
              },
            ],
            [
              { text: '', border: [false, false, false, false] },
              { text: 'รวมทั้งสิ้น', style: 'header1', fillColor: '#b2ebf2' },
              {
                text: ` ${sumAllSalary
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'table1',
                fillColor: '#b2ebf2',
              },
              {
                text: `${
                  sumAllOt ||
                  sumAllowance?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                }`,
                style: 'table1',
                fillColor: '#b2ebf2',
              },
              {
                text: `${sumAllSSO
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'table1',
                fillColor: '#b2ebf2',
              },
              {
                text: `${sumTax
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'table1',
                fillColor: '#b2ebf2',
              },
              {
                text: `${(sumAllRevenue - sumAllexpenses)
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'table1',
                fillColor: '#b2ebf2',
              },
              {
                text: '',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },

      {
        margin: [0, 30, 0, 0],
        table: {
          widths: [
            '5%', // 1
            '5%', // 2
            '11%', // 3
            '16%', // 4
            '9%', // 5
            '10.5%', // 6
            '9.5%', // 7
            '9%', // 8
            '9.5%', // 9
            '7.5%', // 10
            '*', // 11
          ],

          body: [
            [
              {
                text: 'โครงการ',
                style: 'table',
              },
              {
                text: 'ลำดับ',
                style: 'table',
              },
              {
                text: 'เลขบัตรประชาชน',
                style: 'table',
              },
              {
                text: 'รายชื่อ',
                style: 'table',
              },
              {
                text: 'เงินเดือนที่จ่าย',
                style: 'table',
              },
              {
                text: 'เบี้ยเลี้ยง/ค่าทำงานวันหยุด',
                style: 'table',
              },
              {
                text: 'เงินเพิ่มพิเศษ',
                style: 'table',
                border: [true, true, true, true],
              },
              {
                text: 'รวมเงินได้',
                style: 'table',
                border: [true, true, true, true],
              },
              {
                text: 'หัก [ประกันสังคม (5%)]',
                style: 'table',
              },
              {
                text: 'หัก [ภาษี ณ ที่จ่าย]',
                style: 'table',
              },
              { text: 'คงเหลือจ่ายจริง', style: 'table' },
            ],

            ...genItemBody,
            [
              { text: 'รวมทั้งหมด', colSpan: 4, style: 'table' },
              { text: '2' },
              { text: '3' },
              { text: '4' },
              {
                text: ` ${sumAllSalary
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'table1',
              },
              {
                text: `${
                  sumAllOt ||
                  sumAllowance?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                }`,
                style: 'table1',
              },
              {
                text: ` ${sumAllExtra
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'table1',
              },
              {
                text: `${sumTotal
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'table1',
              },
              {
                text: `${sumAllSSO
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'table1',
              },

              {
                text: `${sumTax
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'table1',
              },
              {
                text: `${(sumAllRevenue - sumAllexpenses)
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'table1',
              },
            ],
          ],
        },
      },
      {
        margin: [0, 0, 0, 100],
        table: {
          widths: ['*'],
          body: [
            [
              {
                text: 'หมายเหตุ',
                style: 'topic',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: `${note || ''}`,
                style: 'header',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],

    footer: [
      {
        magin: [0, -2, 0, 0],
        table: {
          widths: ['20%', '60%', '20%'],
          body: [
            [
              {
                text: '                      ',
                style: 'topic1',
                border: [false, false, false, false],
              },
              {
                text: '                              ',
                style: 'topic1',
                border: [false, false, false, false],
              },
              {
                text: '                       ',
                style: 'topic1',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'ผู้จัดทำ',
                style: 'table',
                border: [false, false, false, false],
              },
              {
                text: 'ผู้ตรวจสอบ',
                style: 'table',
                border: [false, false, false, false],
              },
              {
                text: 'ผู้อนุมัติ',
                style: 'table',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'วันที่................................',
                style: 'table',
                border: [false, false, false, false],
              },
              {
                text: 'วันที่................................',
                style: 'table',
                border: [false, false, false, false],
              },
              {
                text: 'วันที่................................',
                style: 'table',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],

    styles: {
      header: {
        fontSize: 10,
        // bold: true,
        alignment: 'left',
      },
      header1: {
        fontSize: 8,
        // bold: true,
        alignment: 'center',
      },
      headerPage: {
        fontSize: 12,
        bold: true,
        alignment: 'left',
      },
      table: {
        bold: true,
        alignment: 'center',
      },
      table1: {
        fontSize: 9,
        bold: true,
        alignment: 'right',
      },
      table2: {
        fontSize: 9,
        bold: true,
        alignment: 'left',
      },
      sum: {
        color: 'red',
        fontSize: 9,
        bold: true,
        alignment: 'right',
      },
      sum1: {
        color: 'red',
        fontSize: 10,
        bold: true,
        alignment: 'center',
      },
      tabHead: {
        bold: true,
        color: 'white',
      },
      topic: {
        fontSize: 12,
        bold: true,
        alignment: 'left',
        decoration: 'underline',
      },
      topic1: {
        fontSize: 12,
        bold: true,
        alignment: 'center',
        decoration: 'underline',
      },
      topic2: {
        fontSize: 12,
        bold: true,
        alignment: 'right',
        decoration: 'underline',
      },
    },
    images: {},
  };
  pdfMake.createPdf(documentRef).open();
};
export default SalaryReport;
// [
//   { text: "1" },
//   { text: "2" },
//   { text: "3" },
//   { text: "4" },
//   { text: "5" },
//   { text: "6" },
//   { text: "7" },
//   { text: "8" },
//   { text: "9" },
//   { text: "10" },
//   { text: "11" },
//   { text: "12" },
//   { text: "13" },
//   { text: "14" },
//   { text: "15" },
// ],
